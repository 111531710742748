import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

// Store
import { marteStore } from '@Store/marte';

// Hook
import { valPackage } from '@Hooks/useValidatePlanet';

// Styles
import lines from '@Components/Planets/lines.module.scss';
import buttons from '@Sass/components/buttons.module.scss';

// Images
import tank from '@Assets/images/planet-tank.svg';
import lock from '@Assets/images/lock.svg';
import grid from '@Assets/images/planet-grid.png';
import _colonizar from '@Assets/images/colonizar.svg';
import _rayo from '@Assets/images/rayo.svg';
import empieza from '@Assets/images/planets/empieza.svg';
import marte from '@Assets/images/planets/marte.svg';

export const Marte = ({ marteInfo }) => {
  const [skills, setSkills] = useState([
    {
      skill: '',
    },
    {
      skill: '',
    },
  ]);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('Marte');

  // Store de marte
  const { getMarte, dataMarte } = marteStore(
    (state) => ({
      getMarte: state.getMarte,
      dataMarte: state.dataMarte,
    }),
    shallow
  );

  const { validateProject } = valPackage();

  useEffect(() => {
    if (marteInfo.titulo) {
      setSkills(marteInfo.skills);
      setDescription(marteInfo.descripcion);
      setTitle(marteInfo.titulo);
    }
  }, [marteInfo]);
  /* 
  useEffect(() => {
    console.log(dataMarte);
  }, [dataMarte]); */

  return (
    <div className="planetGridItem">
      <div className="planetFigure">
        <img src={marte} alt="planet" className="planetImage" />
        {getMarte().lockedPlanet !== 'desbloqueado' && (
          <div className="lockedPlanet">
            <img src={grid} alt="lock" />
            <img src={lock} alt="lock" />
          </div>
        )}

        <div className="tankCount">
          <img src={tank} alt="tank" />
          <span>x3</span>
        </div>
      </div>

      <div className="planetInfo">
        <h2>
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
          <span className="planetUnderline"></span>
        </h2>
        <span className="planetUnderline"></span>
        <p dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>

      {getMarte().id ? (
        <button
          className={`${
            dataMarte.complete === 1
              ? buttons.buttonPlanetCompleted
              : dataMarte.complete === 2
              ? buttons.buttonPlanetIncomplete
              : dataMarte.complete === 0
              ? buttons.buttonPlanet
              : buttons.buttonPlanet
          }`}
          onClick={() => validateProject('marte', 1)}
        >
          {dataMarte.label}
        </button>
      ) : (
        <img src={empieza} alt="empieza" onClick={() => validateProject('marte', 1)}/>
      )}
    </div>
  );
};
